// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-page-page-tsx": () => import("./../../../src/templates/Page/Page.tsx" /* webpackChunkName: "component---src-templates-page-page-tsx" */),
  "component---src-templates-sermon-sermon-tsx": () => import("./../../../src/templates/Sermon/Sermon.tsx" /* webpackChunkName: "component---src-templates-sermon-sermon-tsx" */),
  "component---src-templates-sermons-sermons-tsx": () => import("./../../../src/templates/Sermons/Sermons.tsx" /* webpackChunkName: "component---src-templates-sermons-sermons-tsx" */)
}

